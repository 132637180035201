import { ArrowBack, Warning } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DirectionIcon, HamburgerIcon, OsmIcon } from '../../icons/Icons';
import { getToLocation } from '../../lib/helperFunctions';
import { Colours } from '../../theme';

const useStyles = makeStyles(() => ({
  navigationWarning: {
    '& > svg': {
      marginBottom: '-4px',
    },
  },
}));

export const GoToDirection = ({ addressFragments, backToAngebote }) => {
  const { strasse, hausnummer, landkreis, plz, ort } = addressFragments;
  const goToLocation = getToLocation({ ...addressFragments });
  const isDesktop = useMediaQuery('(min-width:756px)');
  const { navigationWarning } = useStyles();

  const fullAddress = `${strasse} ${hausnummer}, ${plz} ${ort}, ${landkreis}`;
  const encodedAddress = encodeURIComponent(fullAddress);

  return (
    <Box
      display='flex'
      justifyContent={'center'}
      alignContent={'center'}
      flexDirection={'column'}
      py={4}
      gap={1.5}
    >
      <Warning
        color={'warning'}
        fontSize={'large'}
        sx={{
          alignSelf: 'center',
        }}
      />
      <Typography textAlign='center' className={navigationWarning}>
        Achtung! Du verlässt gleich den MUT-ATLAS. Möchtest du deine
        Standard-Routing-App nutzen und möglicherweise datentransparent werden?
        Oder lieber die OpenStreetMap-Website (OSM)? Bei der OSM musst du dort
        eventuell auf das Menü-Symbol <HamburgerIcon fill={'gray'} /> (oben
        rechts) tippen, damit das Routing aktiviert wird.
      </Typography>
      <Box
        display='flex'
        alignItems='center'
        justifyContent={'center'}
        flexWrap='wrap'
        gap={2}
        mb={2}
      >
        <Typography
          component='a'
          display={'flex'}
          alignItems={'center'}
          gap={1}
          bgcolor={Colours._darkGreen}
          color={'white'}
          borderRadius={'4'}
          textDecoration={'none'}
          className='direction-link-osm'
          border={`1px solid ${Colours._darkGreen}`}
          py={1}
          px={2}
          href={`https://www.openstreetmap.org/search?query=${encodedAddress}`}
          target='_blank'
          rel='nofollow noopener noreferrer'
        >
          <Typography component={'span'}>Zur OSM</Typography>
          <OsmIcon />
        </Typography>
        {!isDesktop ? (
          <Typography
            component={'a'}
            className='direction-link'
            display={'flex'}
            alignItems={'center'}
            textDecoration={'none'}
            gap={1}
            color={Colours._darkGreen}
            borderRadius={'4'}
            border={`1px solid ${Colours._darkGreen}`}
            href={goToLocation}
            target='_blank'
            py={1}
            px={2}
            rel='nofollow noopener noreferrer'
          >
            <Typography component={'span'}>Zur Standard-Routing-App</Typography>

            <DirectionIcon />
          </Typography>
        ) : null}
      </Box>

      <Button
        onClick={(e) => {
          e.stopPropagation();
          backToAngebote();
        }}
      >
        <ArrowBack />
        <Typography ml={2} variant='body2' fontSize={'.75rem'}>
          Zurück zum Angebot
        </Typography>
      </Button>
    </Box>
  );
};
