import { Box } from '@mui/material';
import React from 'react';

export function SocialLinks({ height = 20, width = 20 }) {
  return (
    <Box display='flex' gap={1}>
      <a
        href='  https://www.facebook.com/profile.php?id=61568228413697'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src='Facebook_Logo_Primary.png'
          alt=''
          width={width}
          height={height}
        />
      </a>
      <a
        href='https://www.instagram.com/mut_atlas/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src='Instagram_Glyph_Gradient.png'
          alt=''
          width={width}
          height={height}
        />
      </a>
    </Box>
  );
}
